import {
  Home,
  Inbox,
  LineChart,
  Settings,
  ShieldAlert,
  Star,
} from 'lucide-react'

export const FILTER_HOME_IMOVEIS = {
  'Tipo de imovel': {
    values: [
      'Apartamento',
      'Casa de condomínio',
      'Casa de vila',
      'Cobertura',
      'Comercial',
      'Andar / Laje corporativa',
      'Casa',
      'Conjunto / Sala',
      'Galpão / Depósito / Armazém',
      'Garagem',
      'Hotel / Motel / Pousada',
      'Loja / Salão / Ponto',
      'Prédop inteiro',
      'Terrenos / Lotes comerciais',
      'Comodo',
      'Fazenda / Sitio / Chácara',
      'Flat',
      'Industrial',
      'Fundos imobolíarios',
      'Loteamento',
      'Na planta',
      'Novo',
      'Parceria',
      'Kitnet',
      'Loft',
      'Sobrado',
      'Studio',
      'Terreno / Lote / Condomínio',
    ],
    label: 'type_propertie',
  },
  Categoria: {
    values: ['Aluguel', 'Investimento', 'Temporada', 'Venda'],
    label: 'categorie',
  },
  Preço: { values: ['Preço crescente', 'Preço decrescente'], label: 'price' },
} as const
export const FILTER_MODAL_IMOVEIS = {
  combobox: [
    {
      name: 'bathrooms',
      label: 'Banheiros',
      values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '+10'],
    },
    {
      name: 'suites',
      label: 'Suites',
      values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '+10'],
    },
    {
      name: 'bedrooms',
      label: 'Quartos',
      values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '+10'],
    },
    {
      name: 'garages',
      label: 'Garagem',
      values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '+10'],
    },
    {
      name: 'rooms',
      label: 'Sala',
      values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '+10'],
    },
  ],
}

export const SIDE_BAR = [
  {
    icon: Home,
    path: '/',
    label: 'Home',
    lvl: ['imobiliaria', 'proprietário', 'corretor', 'admin', 'member'],
  },
  {
    icon: LineChart,
    path: '/dashboard',
    label: 'Painel',
    lvl: ['imobiliaria', 'proprietário', 'corretor', 'admin', 'member'],
  },
  {
    icon: Inbox,
    path: '/dashboard/inbox',
    label: 'Caixa de entrada',
    lvl: ['corretor', 'imobiliaria', 'admin', 'proprietário', 'member'],
  },
  {
    icon: Star,
    path: '/dashboard/favorite',
    label: 'Favoritos',
    lvl: ['imobiliaria', 'proprietário', 'corretor', 'admin', 'member'],
  },
  {
    icon: ShieldAlert,
    label: 'Administrativo',
    lvl: ['corretor', 'imobiliaria', 'admin', 'proprietário', 'member'],
    values: [
      {
        label: 'Meus anuncios',
        path: '/dashboard/admin/ads',
        lvl: ['corretor', 'imobiliaria', 'admin', 'proprietário', 'member'],
      },
      {
        label: 'Encomendas',
        path: '/dashboard/admin/orders',
        lvl: ['corretor', 'admin', 'proprietário'],
      },
      {
        label: 'Meus corretores',
        path: '/dashboard/admin/broker',
        lvl: ['imobiliaria', 'admin'],
      },
    ],
  },
  {
    icon: Settings,
    label: 'Configurações',
    lvl: ['imobiliaria', 'proprietário', 'corretor', 'admin'],
    values: [
      {
        label: 'Privacidade',
        path: '/dashboard/settings/privacy',
        lvl: ['imobiliaria', 'proprietário', 'corretor', 'admin'],
      },
      {
        label: 'Admin',
        path: '/dashboard/settings/admin',
        lvl: ['admin'],
      },
    ],
  },
]

export const FORM_CREATE_IMOVEIS = {
  description: {
    label: 'Descrição',
  },
  details: {
    label: 'Midia',
  },
  feature: {
    label: 'Localização',
  },
  location: {
    label: 'Detalhes',
  },
  midia: {
    label: 'Características',
  },
}

export const REGISTER_IMOVEIS_OPTIONS = {
  'Características do imóvel': [
    'Aceita Pets',
    'Ar-Condicionado',
    'Área de Serviço',
    'Armário embutido',
    'Armário embutido no quarto',
    'Armário no banheiro',
    'Armários na cozinha',
    'Box blindex',
    'Chuveiro a gás',
    'Closet',
    'Conexão à internet',
    'Cozinha americana',
    'Cozinha grande',
    'Depósito',
    'Escritório',
    'Interfone',
    'Janelas grandes',
    'Lareira',
    'Mobiliado',
    'Quintal',
  ],
  Condomínio: [
    'Acesso para deficientes',
    'Bicicletário',
    'Elevador',
    'Gerador elétrico',
    'Lavanderia',
    'Recepção',
    'Sauna',
    'SPA',
  ],
  'Lazer e Esporte': [
    'Academia',
    'Churrasqueira',
    'Cinema',
    'Espaço Gourmet',
    'Espaço verde/Parque',
    'Jardim',
    'Piscina',
    'Playground',
    'Quadra Poliesportiva',
    'Quadra de Tênis',
    'Salão de Festas',
    'Salão de Jogos',
  ],
  Segurança: [
    'Circuito de Segurança',
    'Condomínio Fechado',
    'Portão eletrônico',
    'Portaria 24h',
    'Sistema de alarme',
  ],
}
export type REGISTER_IMOVEIS_VALUES = keyof typeof REGISTER_IMOVEIS_OPTIONS
export const REGISTER_IMOVEIS_OPTIONS_KEYS = {
  'Características do imóvel': 'features_of_the_property',
  Condomínio: 'condominium',
  'Lazer e Esporte': 'leisure_and_sport',
  Segurança: 'security',
}

export const TYPE_USER = [
  'Membro/Proprietario',
  'Corretor',
  'Imobiliaria',
] as const
