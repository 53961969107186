'use client'
import useQueryParams from '@/components/hook/use-params'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Slider } from '@/components/ui/slider'
// import { toast } from '@/components/ui/use-toast'
import {
  FILTER_HOME_IMOVEIS,
  FILTER_MODAL_IMOVEIS,
  REGISTER_IMOVEIS_OPTIONS,
  REGISTER_IMOVEIS_VALUES,
} from '@/constant/filters'
import { formatCurrency } from '@/lib/utils'
import { ChevronDown, ListRestart, Loader2, Search, Star } from 'lucide-react'
import { useRouter } from 'next/navigation'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { OptionModal } from './option-modal'
import { toast } from 'sonner'

export type FilterOptionsInfoState = {
  bathrooms?: any
  suites?: any
  bedrooms?: any
  garages?: any
  rooms?: any
  areaTotalMin?: number
  areaTotalMax?: number
  areaUtilMin?: number
  areaUtilMax?: number
  transaction?: string
  feature?: string[]
  types: string[]
  prices: number[]
}
type Props = {
  onSubmit: (params: FilterOptionsInfoState) => void
  isOrder?: boolean
  isLoading?: boolean
}
export default function FilterOptions({
  onSubmit,
  isOrder = false,
  isLoading = false,
}: Props) {
  const router = useRouter()
  const { obj } = useQueryParams()
  const [info, setInfo] = useState<FilterOptionsInfoState>({
    prices: [0, 5000000000],
    types: [],
    feature: [],
    ...obj,
  })
  const handleSalveFilter = () => {
    toast.success("Salvo com sucesso!")
  }
  const handleResetFilter = () => {
    router.push("?")
    localStorage.removeItem('filter')
  }

  useEffect(() => {
    const filter = localStorage.getItem('filter')
    if (filter) {
      setInfo(JSON.parse(filter))
    }
  }, [])
  useEffect(() => {
    localStorage.setItem('filter', JSON.stringify(info))
  }, [info])
  return (
    <>
      <div className="flex w-full flex-col h-full ">
        <OptionModal label="Categoria">
          {FILTER_HOME_IMOVEIS[
            Object.keys(
              FILTER_HOME_IMOVEIS,
            )[1] as keyof typeof FILTER_HOME_IMOVEIS
          ].values.map((value) => (
            <div key={value} className="col-span-6 flex items-center space-x-2">
              <Checkbox
                id={value}
                disabled={isLoading}
                className="cursor-pointer"
                checked={info.transaction === value}
                onCheckedChange={(e) => {
                  const newValue = e ? value : ''
                  setInfo((prev) => ({
                    ...prev,
                    transaction: newValue,
                  }))
                }}
              />
              <label
                htmlFor={value}
                className="text-sm font-medium cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {value}
              </label>
            </div>
          ))}
        </OptionModal>
        <OptionModal label="Tipo do imóveis">
          {FILTER_HOME_IMOVEIS[
            Object.keys(
              FILTER_HOME_IMOVEIS,
            )[0] as keyof typeof FILTER_HOME_IMOVEIS
          ].values.map((value) => (
            <div key={value} className="col-span-6 flex items-center space-x-2">
              <Checkbox
                id={value}
                disabled={isLoading}
                className="cursor-pointer"
                checked={info.types.includes(value)}
                onCheckedChange={(e) => {
                  const newTypes = e
                  ? [...info.types, value]
                  : info.types.filter((type) => type !== value)
                setInfo((prev) => ({
                  ...prev,
                  types: newTypes,
                }))
                }}
              />
              <label
                htmlFor={value}
                className="text-sm font-medium cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {value}
              </label>
            </div>
          ))}
        </OptionModal>
        <OptionModal label="Detalhes" className="flex mb-1 flex-wrap">
          {FILTER_MODAL_IMOVEIS.combobox.map((f, index) => (
            <div
              className="flex flex-1 flex-col gap-2"
              key={`${f.label}-${index}`}
            >
              <label className="text-gray-500">{f.label}</label>
              <Select
                disabled={isLoading}
                onValueChange={(e) =>
                  setInfo((prev) => ({
                    ...prev,
                    [f.name]: e,
                  }))
                }
                defaultValue={
                  info[
                    f.name as
                      | 'bathrooms'
                      | 'suites'
                      | 'bedrooms'
                      | 'garages'
                      | 'rooms'
                  ] || ''
                }
              >
                <SelectTrigger className="items-center gap-2 rounded-md border border-[#d0d5dd] px-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                  <SelectValue
                    placeholder="Quantidade"
                    className="text-[12px]"
                  />
                </SelectTrigger>
                <SelectContent className="outline-none focus:outline-none">
                  <SelectGroup>
                    {f.values.map((v) => (
                      <SelectItem
                        key={v}
                        value={v}
                        className="cursor-pointer hover:bg-gray-400/20 text-[12px]"
                      >
                        {v}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          ))}
        </OptionModal>
        <OptionModal label="Area" className="flex mb-1">
          <div className="flex w-full justify-around flex-1 flex-wrap gap-4">
            <div>
              <h1 className="relative">
                Area total{' '}
                <span className="absolute text-[8px]">
                  { info.types?.includes('Fazenda / Sitio / Chácara') && '(hectares)'}
                  { !info.types?.includes('Fazenda / Sitio / Chácara') && '(min²)'}
                </span>
              </h1>
              <div className="flex items-center justify-between gap-4">
                <Input
                  disabled={isLoading}
                  placeholder="100"
                  className="max-w-[100px]"
                  type="number"
                  value={info.areaTotalMin || ''}
                  onChange={(e) =>
                    setInfo((prev) => ({
                      ...prev,
                      areaTotalMin: Number(e.target.value),
                    }))
                  }
                />
              </div>
            </div>
            <div>
              <h1 className="relative">
                Area total{' '}
                <span className="absolute text-[8px]">
                  { info.types?.includes('Fazenda / Sitio / Chácara') && '(hectares)'}
                  { !info.types?.includes('Fazenda / Sitio / Chácara') && '(min²)'}
                </span>
              </h1>
              <div className="flex items-center justify-between gap-4">
                <Input
                  disabled={isLoading}
                  placeholder="100"
                  type="number"
                  className="max-w-[100px]"
                  value={info.areaTotalMax || ''}
                  onChange={(e) =>
                    setInfo((prev) => ({
                      ...prev,
                      areaTotalMax: Number(e.target.value),
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex w-full justify-around flex-1 flex-wrap gap-4">
            <div>
              <h1 className="relative">
                Area Útil <span className="absolute text-[10px]">(min²)</span>
              </h1>
              <div className="flex items-center justify-between gap-4">
                <Input
                  disabled={isLoading}
                  placeholder="100"
                  type="number"
                  className="max-w-[100px]"
                  value={info.areaUtilMin || ''}
                  onChange={(e) =>
                    setInfo((prev) => ({
                      ...prev,
                      areaUtilMin: Number(e.target.value),
                    }))
                  }
                />
              </div>
            </div>
            <div>
              <h1 className="relative">
                Area Útil{' '}
                <span className="absolute text-[8px]">
                  { info.types?.includes('Fazenda / Sitio / Chácara') && '(hectares)'}
                  { !info.types?.includes('Fazenda / Sitio / Chácara') && '(min²)'}
                </span>
              </h1>
              <div className="flex items-center justify-between gap-4">
                <Input
                  disabled={isLoading}
                  placeholder="100"
                  type="number"
                  className="max-w-[100px]"
                  value={info.areaUtilMax || ''}
                  onChange={(e) =>
                    setInfo((prev) => ({
                      ...prev,
                      areaUtilMax: Number(e.target.value),
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </OptionModal>
        <OptionModal label="Características">
          {REGISTER_IMOVEIS_OPTIONS['Características do imóvel'].map(
            (feature, index) => (
              <div
                key={index}
                className="flex col-span-4 items-center space-x-2"
              >
                <Checkbox
                  disabled={isLoading}
                  id={feature}
                  className="cursor-pointer"
                  checked={info.feature?.includes(feature) || false}
                  onCheckedChange={(e) => {
                    const newFeatures = e
                      ? [...(info.feature || []), feature]
                      : info.feature?.filter((f) => f !== feature) || []
                    setInfo((prev) => ({
                      ...prev,
                      feature: newFeatures,
                    }))
                  }}
                />
                <label
                  htmlFor={feature}
                  className="text-sm font-medium cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {feature}
                </label>
              </div>
            ),
          )}
        </OptionModal>
        <OptionModal label="Condomínio">
          {' '}
          {REGISTER_IMOVEIS_OPTIONS['Condomínio'].map((feature, index) => (
            <div key={index} className="flex col-span-4 items-center space-x-2">
              <Checkbox
                disabled={isLoading}
                id={feature}
                className="cursor-pointer"
                checked={info.feature?.includes(feature) || false}
                onCheckedChange={(e) => {
                  const newFeatures = e
                    ? [...(info.feature || []), feature]
                    : info.feature?.filter((f) => f !== feature) || []
                  setInfo((prev) => ({
                    ...prev,
                    feature: newFeatures,
                  }))
                }}
              />
              <label
                htmlFor={feature}
                className="text-sm font-medium cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {feature}
              </label>
            </div>
          ))}
        </OptionModal>
        <OptionModal label="Lazer e Esporte">
          {REGISTER_IMOVEIS_OPTIONS['Lazer e Esporte'].map((feature, index) => (
            <div key={index} className="flex col-span-4 items-center space-x-2">
              <Checkbox
                id={feature}
                disabled={isLoading}
                className="cursor-pointer"
                checked={info.feature?.includes(feature) || false}
                onCheckedChange={(e) => {
                  const newFeatures = e
                    ? [...(info.feature || []), feature]
                    : info.feature?.filter((f) => f !== feature) || []
                  setInfo((prev) => ({
                    ...prev,
                    feature: newFeatures,
                  }))
                }}
              />
              <label
                htmlFor={feature}
                className="text-sm font-medium cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {feature}
              </label>
            </div>
          ))}
        </OptionModal>
        <OptionModal label="Segurança">
          {REGISTER_IMOVEIS_OPTIONS['Segurança'].map((feature, index) => (
            <div key={index} className="flex col-span-4 items-center space-x-2">
              <Checkbox
                disabled={isLoading}
                id={feature}
                className="cursor-pointer"
                checked={info.feature?.includes(feature) || false}
                onCheckedChange={(e) => {
                  const newFeatures = e
                    ? [...(info.feature || []), feature]
                    : info.feature?.filter((f) => f !== feature) || []
                  setInfo((prev) => ({
                    ...prev,
                    feature: newFeatures,
                  }))
                }}
              />
              <label
                htmlFor={feature}
                className="text-sm font-medium cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {feature}
              </label>
            </div>
          ))}
        </OptionModal>
        <OptionModal label="Preço" className="flex ">
          <div className="flex flex-col gap-2 p-4">
            <div className=" flex items-center justify-between gap-4 ">
              <div className="border flex gap-0.5 items-center rounded-lg px-2">
                <p>R$</p>
                <Input
                  disabled={isLoading}
                  placeholder="R$10.000.000,00"
                  value={info.prices[0]}
                  className="border-none"
                  type="text"
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.-]+/g, '')
                    const newPrices = [...info.prices]
                    newPrices[0] = Number.parseFloat(value) || 0
                    setInfo((prev) => ({ ...prev, prices: newPrices }))
                  }}
                />
              </div>

              <div className="h-[1px] w-[10rem] bg-[#d9d9d9] max-md:sr-only" />
              <div className="border flex gap-0.5 items-center rounded-lg px-2">
                <p>R$</p>
                <Input
                  disabled={isLoading}
                  placeholder="R$10.000.000,00"
                  value={info.prices[1]}
                  className="border-none"
                  type="text"
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.-]+/g, '')
                    const newPrices = [...info.prices]
                    newPrices[1] = Number.parseFloat(value) || 0
                    setInfo((prev) => ({ ...prev, prices: newPrices }))
                  }}
                />
              </div>
            </div>
            <Slider
              value={info.prices}
              defaultValue={info.prices}
              onValueChange={(e) => setInfo((prev) => ({ ...prev, prices: e }))}
              step={2}
              max={5000000000}
            />
          </div>
        </OptionModal>
      </div>
      {
        <div className="flex flex-col gap-4 static">
          <Button className="flex gap-4 py-4" onClick={() => onSubmit(info)} disabled={isLoading}>
            {isLoading && <Loader2 className="animate-spin" />}
            {!isLoading && <Search size={20} />}
            {!isOrder && isLoading ? 'Buscar' : 'Encomendar'}
          </Button>
          <div className="flex flex-wrap items-center justify-between gap-4 max-sm:justify-center">
            <div
              onClick={handleResetFilter}
              className="flex cursor-pointer items-center gap-4 text-[#7b818f]/80 hover:text-[#7b818f]"
            >
              <ListRestart className="" />
              Resetar filtro
            </div>
            <div
              onClick={handleSalveFilter}
              className="flex cursor-pointer items-center gap-4 text-[#7b818f]/80 hover:text-[#7b818f]"
            >
              <Star />
              Salvar filtro
            </div>
          </div>
        </div>
      }
    </>
  )
}
