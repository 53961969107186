import { cn } from "@/lib/utils";
import { ChevronDown } from "lucide-react";
import { ReactNode, useState } from "react";

type Props = {
  children: ReactNode;
  label: string;
  isOpen?: boolean;
  className?: string
};

export const OptionModal = ({ children, label, isOpen = false, className }: Props) => {
  const [open, setOpen] = useState(isOpen);
  return (
    <>
      <div
        className="flex p-1 w-full justify-between hover:bg-slate-300/30 bg-slate-100/80 px-2 py-1 cursor-pointer rounded-lg"
        onClick={() => setOpen(!open)}
        data-open={open}
      >
        <h1 className="font-bold">{label}</h1>
        <ChevronDown className={`${open ? "rotate-180" : ""}`} />
      </div>
      <div className={cn([`grid grid-cols-12 gap-x-2 gap-y-1 mt-2 overflow-y-auto ${open ? "max-h-[100px]" : "max-h-0"} transition-all duration-300 ease-in-out`, className])}>
        {children}
      </div>
    </>
  );
};
